<template>
  <router-view></router-view>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'SpecialLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.special_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.special_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.special_keyword')}` }]
  },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>
